<template>
    <div
        class="company"
        role="button"
        @click="onLoadDomain"
    >
        <div class="company__wrap" v-if="domain">
            <logo
                :logo="domain.icon"
                :company-name="domain.name"
            />

            <h3
                class="company__name"
                :title=domain.name
            >
                {{ domain.name }}
            </h3>
        </div>

        <i
            v-if="domain && domain.is_block"
            class="company__blocked-icon"
            v-tooltip="{
                classes: 'tooltip--dark',
                content: 'Портал заблокирован за неуплату'
            }"
        />
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: 'Company',
        components: {
            Logo: () => import('./Logo')
        },

        props: {
            domain: {
                required: true
            },

            current: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        methods: {
            async onLoadDomain() {
                if (this.domain && this.domain.link && !this.current) {
                     try {
                         const request = session.get(this.domain.link);
                         const response = await request;

                         window.location.href = response.data.link;
                     } catch (err) {
                         console.error(err);
                     }
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .company {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px;
        min-width: 0px;
        &__wrap {
            display: flex;
            align-items: center;
            min-width: 0px;
        }

        &__name {
            display: block;
            overflow: hidden;
            margin-left: 10px;
            font-weight: 500;
            white-space: nowrap;
            word-break: break-word;
            text-overflow: ellipsis;
        }

        &__angle {
            margin-left: 10px;
            transition: transform $ease;
        }

        &__blocked-icon {
            @include icon($sidebar-locked, 13px);
        }
    }
</style>
